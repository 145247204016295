import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LandingComponent } from '@app/components/landing/landing.component';

const routes: Routes = [
  {path: '', component: LandingComponent},

  {
    path: 'orders',
    loadChildren: () => import('@app/components/orders/orders.module').then(m => m.OrdersModule),
    data: {
      title: 'Orders Management'
    }
  },

  {
    path: 'gallery',
    loadChildren: () => import('@app/components/gallery/gallery-routing.module').then(m => m.GalleryRoutingModule),
    data: {
      title: 'Gallery'
    }
  },

  {
    path: 'admin',
    loadChildren: () => import('@app/components/admin/admin.module').then(m => m.AdminModule),
    data: {
      title: 'Orders Management'
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class ComponentsRoutingModule {
}
