import { NgModule } from '@angular/core';
import { ComponentsRoutingModule } from '@app/components/components-routing.module';
import { LandingComponent } from '@app/components/landing/landing.component';
import { CommonComponentsModule } from '@common/common.module';

@NgModule({
  declarations: [
    LandingComponent,
  ],
  imports: [
    CommonComponentsModule,

    ComponentsRoutingModule
  ],
  exports: [],
})
export class ComponentsModule {
}
