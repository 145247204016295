import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@common/service/auth.service';
import { Role } from '@common/model/auth/role';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.authService.getSession().subscribe(async session => {
      if (session.isValid()) {
        // select URL based on current role

        const url = session.hasPermission(Role.artist)
          ? '/orders/new'
          : '/admin/products/types';

        await this.router.navigate([url]);
      } else {
        await this.router.navigate(['/auth/sign-in'], {replaceUrl: true});
      }
    });
  }

}
